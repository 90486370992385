<template>
  <div>
    <portal to="page-top-title">Blacklists</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'blacklists-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/blacklists" :url-query="{ with: ['file'], with_count: ['emails'] }" data-prop="blacklists" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>File Name</th>
          <th>Contacts</th>
          <th>Rows Parsed</th>
          <th class="text-center">Status</th>
          <th class="text-right">Created At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="blacklist in blacklists" :key="`blacklists-tr-${blacklist.id}`" :value="blacklist">
          <td>{{ blacklist.id }}</td>
          <td>{{ blacklist.name }}</td>
          <td>
            <em class="text-info" v-if="!blacklist.file">None</em>
            <nullable v-else :value="blacklist.file.original_name"/>
          </td>
          <td>
            <badge title="Total">{{ blacklist.emails_count }}</badge>
          </td>
          <td>
            <em class="text-info" v-if="!blacklist.file">None</em>
            <badge title="Total" v-else>{{ blacklist.file.rows_parsed }}</badge>
          </td>
          <td class="text-center">
            <em class="text-info" v-if="!blacklist.file">None</em>
            <badge-status v-else :value="blacklist.file.status_text"/>
          </td>
          <td class="text-right">{{ blacklist.created_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'blacklists-edit', params: { id: blacklist.id } }" icon="fa-edit" >Edit</btn>
            <btn :route="{ name: 'blacklists-single', params: { id: blacklist.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import Nullable from '@/views/components/simple/Nullable'

export default {
  name: 'BlacklistsList',
  metaInfo: {
    title: 'Blacklists'
  },
  components: {
    BoxTable,
    Btn,
    Badge,
    BadgeStatus,
    Nullable
  },
  data () {
    return {
      blacklists: []
    }
  }
}
</script>
